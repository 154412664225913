






















import Vue from 'vue';
import OwnerUserService from '@/services/customers/CustomerUserService';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  mounted() {
    this.setBreadCrumb();
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.guest.GuestDeleteAccount.meta.title').toString(),
    };
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.guest.GuestDeleteAccount.breadcrumb.last' },
      ]);
    },

    deleteAccount() {
      OwnerUserService.deleteAccount().then(() => {
        this.$store.dispatch('AuthStoreModule/logout').then(() => {
          this.$router.push('/');
        });
      });
    },
  },
});
