import ApiService from '@/services/ApiService';

const API_URL = '/api/Customer/User/';

class CustomerUserService {
  deleteAccount() {
    return ApiService.delete(`${API_URL}DeleteAccount`);
  }
}

export default new CustomerUserService();
